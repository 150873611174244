import React from "react";

const Styles = ({ uniqueId, styles }) => {
  if (!styles) {
    return <></>;
  }

  return (
    <style>{`
        #${uniqueId} {
            width: ${styles.width};
            --plyr-color-main: ${styles.primaryColor || "#00b2ff"};
            --plyr-video-control-color: ${styles.secondaryColor || "#ffffff"};
        }
    `}</style>
  );
};

export default Styles;
